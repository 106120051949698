<template>
  <div class="main">
    <div class="left">
      <el-form :model="ruleForm" ref="form" label-width="80px">
        <el-form-item label="提交类型">
          <el-select
            style="width: 100%;"
            v-model="ruleForm.type"
            placeholder="选择作用范围"
          >
            <el-option
              v-for="(val, ind) in typeLi"
              :key="ind"
              :label="val"
              :value="val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="影响区域">
          <el-checkbox-group v-model="ruleForm.area" size="medium">
            <el-checkbox-button
              v-for="(val, ind) in areaLi"
              :key="ind"
              :label="val"
              >{{ val }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="提交简介">
          <el-input v-model="ruleForm.des" placeholder="50字内"></el-input>
        </el-form-item>
        <el-form-item label="提交详情">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="提交详情"
            v-model="ruleForm.text"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="备注页脚">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="备注页脚"
            v-model="ruleForm.foot"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">
            复制
          </el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <textarea class="right" id="code" v-model="showInfo"></textarea>
  </div>
</template>

<script>
import config from "../../public/config.json";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      typeLi: [],
      areaLi: [],
      ruleForm: {
        type: "进度",
        area: [],
        des: "",
        text: "",
        foot: ""
      }
    };
  },
  mounted() {
    console.log(config);
    this.typeLi = config.type;
    this.areaLi = config.area;
  },
  computed: {
    showInfo() {
      let are = this.ruleForm.area.join(",");
      let str = `${this.ruleForm.type}`;
      if (are !== "") {
        str += `(${are})`;
      }
      if (this.ruleForm.des !== "") {
        str += `:${this.ruleForm.des}`;
      }
      if (this.ruleForm.text !== "") {
        let temp = this.ruleForm.text.replace(/，/gi, ",");
        temp = temp.split(",");
        let temp2 = "";
        temp.forEach(element => {
          if (temp2 === "") {
            temp2 = element;
          } else {
            temp2 = `${temp2}
${element}`;
          }
        });
        str = `${str}

${temp2}`;
      }
      if (this.ruleForm.foot !== "") {
        str = `${str}

${this.ruleForm.foot}`;
      }
      return str;
    }
  },
  methods: {
    submitForm() {
      const dom = document.getElementById("code");
      dom.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
    },
    resetForm() {
      this.ruleForm = {
        type: "新功能",
        area: [],
        des: "",
        text: "",
        foot: ""
      };
    }
  }
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
    margin-left: 16px;
  }
}
</style>
